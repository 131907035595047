var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('div',{staticClass:"display-1"},[_vm._v("Tuotteet")]),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_c('v-spacer'),(_vm.isAuthorized('product', 'create'))?_c('v-btn',{staticClass:"mb-2 mr-2 ml-auto",attrs:{"color":"info"},on:{"click":function($event){_vm.$store.state.product.product = {};
          _vm.productDialog = true;
          _vm.editValue = false;}}},[_vm._v("Uusi tuote")]):_vm._e(),(_vm.isAuthorized('product', 'edit') && _vm.products.length != 0)?_c('v-btn',{staticClass:"mb-2 mr-2 ml-auto",attrs:{"color":"primary"},on:{"click":_vm.changeAccountingIdToInvoices}},[_vm._v("Liitä tiliöintikoodit laskuille")]):_vm._e(),(_vm.isAuthorized('product', 'create') && _vm.products.length == 0)?_c('v-btn',{staticClass:"mb-2 mr-2 ml-auto",attrs:{"color":"info"},on:{"click":_vm.createDefaultProducts}},[_vm._v("Luo oletustuotteet")]):_vm._e()],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Hae kaikista kentistä...","single-line":"","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col')],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.productNumber",fn:function(ref){
          var item = ref.item;
return [_c('span',[_c('strong',[_vm._v("# "+_vm._s(item.productNumber))])])]}},{key:"item.desc",fn:function(ref){
          var item = ref.item;
return [_c('span',[_c('strong',[_vm._v(_vm._s(item.desc))])])]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.amount))+" ")])]}},{key:"item.taxpr",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.taxpr)+" % ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('product', 'edit'))?_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){_vm.$store.state.product.product = Object.assign({}, item);
              _vm.productDialog = true;
              _vm.editValue = true;}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.isAuthorized('product', 'delete'))?_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei tuotteita")])]},proxy:true}],null,true)})],1),_c('product-dialog',{attrs:{"edit":_vm.editValue},model:{value:(_vm.productDialog),callback:function ($$v) {_vm.productDialog=$$v},expression:"productDialog"}}),_c('download-loader',{attrs:{"loading":_vm.loadingAccountIds,"text":"Päivitetään laskuja","additionalText":"Päivitys voi kestää hetken riippuen laskujen määrästä. Odota hetki..."},model:{value:(_vm.showUpdateLoader),callback:function ($$v) {_vm.showUpdateLoader=$$v},expression:"showUpdateLoader"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }