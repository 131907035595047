<template>
  <v-dialog v-model="dialog" width="410">
    <v-card>
      <v-card-title> {{ title }} </v-card-title>

      <v-card-text class="mt-2">
        <v-form ref="form">
          <v-text-field
            v-model="currentProduct.desc"
            label="Nimi / Kuvaus"
            outlined
            dense
            :rules="validations.required"
          ></v-text-field>

          <v-select
            v-model="currentProduct.productType"
            label="Tuotetyyppi"
            :items="globalValues.productTypes"
            outlined
            dense
            :rules="validations.required"
          ></v-select>

          <v-select
            v-model="currentProduct.itemtype"
            label="Määrän tyyppi"
            :items="globalValues.productItemTypes"
            :rules="validations.required"
            outlined
            dense
          ></v-select>

          <v-text-field
            v-model.number="currentProduct.count"
            v-only-numbers
            v-prevent-paste
            label="Oletusmäärä"
            outlined
            dense
            suffix="kpl"
          ></v-text-field>

          <v-text-field
            v-model.number="currentProduct.amount"
            label="Oletushinta"
            type="number"
            step="0.01"
            outlined
            dense
            :rules="validations.emptyOrPositiveNumber"
            suffix="€"
          ></v-text-field>

          <v-select
            v-model.number="currentProduct.taxpr"
            label="Oletus-alv-kanta"
            :items="globalValues.vatTypes"
            item-text="text"
            item-value="val"
            outlined
            dense
            :rules="validations.zeroOrGreater"
          ></v-select>

          <v-text-field
            v-model.number="currentProduct.accountingId"
            v-only-numbers
            v-prevent-paste
            label="Tiliöintikoodi"
            outlined
            dense
            :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
          ></v-text-field>

          <small
            >Tiliöintikoodi ei päivity automaattisesti laskuille. Päivittääksesi tiliöintikoodit
            laskuille, paina sivun nappia 'Liitä tiliöintikoodit laskuille'.</small
          >
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click.prevent="submit()">{{ btnText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import { mapActions, mapState } from "vuex";
import globalValues from "../../configs/globalValues";
import validations from "@/validations";

export default {
  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },

  mixins: [mixins, invoiceMixins],

  data() {
    return {
      currentProduct: { itemtype: "kpl", productType: "Vuokra" },
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("product", ["product"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    btnText() {
      if (this.edit) {
        return "Tallenna";
      } else {
        return "Luo";
      }
    },

    title() {
      if (this.edit) {
        return "Muokkaa tuotetta";
      } else {
        return "Luo tuote";
      }
    },
  },

  watch: {
    product(val) {
      this.currentProduct = val || {};
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
  },

  methods: {
    ...mapActions("product", ["createProduct", "updateProduct"]),

    async submit() {
      if (this.$refs.form.validate()) {
        if (this.edit) {
          this.updateProduct({
            product: this.currentProduct,
            productId: this.currentProduct._id,
          });
        } else {
          // Create
          await this.createProduct(this.currentProduct);

          this.$refs.form.reset();
        }
      }
    },
  },
};
</script>

<style scoped></style>
