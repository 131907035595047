<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Tuotteet</div>

    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          v-if="isAuthorized('product', 'create')"
          color="info"
          class="mb-2 mr-2 ml-auto"
          @click="
            $store.state.product.product = {};
            productDialog = true;
            editValue = false;
          "
          >Uusi tuote</v-btn
        >
        <v-btn
          v-if="isAuthorized('product', 'edit') && products.length != 0"
          color="primary"
          class="mb-2 mr-2 ml-auto"
          @click="changeAccountingIdToInvoices"
          >Liitä tiliöintikoodit laskuille</v-btn
        >
        <v-btn
          v-if="isAuthorized('product', 'create') && products.length == 0"
          color="info"
          class="mb-2 mr-2 ml-auto"
          @click="createDefaultProducts"
          >Luo oletustuotteet</v-btn
        >
      </v-card-title>

      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Hae kaikista kentistä..."
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :headers="headers"
        :items="products"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <template #[`item.productNumber`]="{ item }">
          <span
            ><strong># {{ item.productNumber }}</strong>
          </span>
        </template>

        <template #[`item.desc`]="{ item }">
          <span
            ><strong>{{ item.desc }}</strong>
          </span>
        </template>

        <template #[`item.amount`]="{ item }">
          <span>{{ formatCurrency(item.amount) }} </span>
        </template>

        <template #[`item.taxpr`]="{ item }">
          <span>{{ item.taxpr }} % </span>
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('product', 'edit')"
              title="Muokkaa"
              small
              @click="
                $store.state.product.product = { ...item };
                productDialog = true;
                editValue = true;
              "
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="isAuthorized('product', 'delete')"
              title="Poista"
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </div>
        </template>

        <template #no-data>
          <h2>Ei tuotteita</h2>
        </template>
      </v-data-table>
    </v-card>

    <!-- Product Dialog -->
    <product-dialog v-model="productDialog" :edit="editValue"></product-dialog>

    <!-- Loader for accountid updates -->
    <download-loader
      v-model="showUpdateLoader"
      :loading="loadingAccountIds"
      text="Päivitetään laskuja"
      additionalText="Päivitys voi kestää hetken riippuen laskujen määrästä. Odota hetki..."
    ></download-loader>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import ProductDialog from "../../components/Product/ProductDialog.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";
import DownloadLoader from "@/components/DownloadLoader.vue";

export default {
  title: "Tuotteet",

  components: { ProductDialog, DownloadLoader },

  mixins: [mixins],

  data: () => ({
    productDialog: false,
    editValue: false,
    loadingAccountIds: false,
    showUpdateLoader: false,
    loading: true,
    options: {},
    dialog: false,
    search: "",
    headers: [
      { text: "Tuotenumero", value: "productNumber" },
      { text: "Nimi / kuvaus", value: "desc" },
      {
        text: "Tuotetyyppi",
        value: "productType",
      },
      {
        text: "Määrän tyyppi",
        value: "itemtype",
      },
      {
        text: "Oletusmäärä",
        value: "count",
      },
      {
        text: "Oletushinta",
        value: "amount",
      },
      {
        text: "Oletus-alv",
        value: "taxpr",
      },
      {
        text: "Tiliöintikoodi",
        value: "accountingId",
      },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("product", ["products", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("product", ["createProduct", "getAllProducts", "inActivateProduct"]),
    ...mapActions("invoice", ["changeProductAccountingIdToInvoices"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();
      let url = `/api/v1/product/get-all?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

      await this.getAllProducts(url);

      this.loading = false;
    },

    createDefaultProducts() {
      if (this.products.length > 0) {
        return this.showPopup(
          "Sinulla on jo tuotteita, et voi luoda oletustuotteita jälkikäteen",
          "error"
        );
      }

      const products = [
        { itemtype: "kpl", productType: "Autopaikka", desc: "Autopaikka", count: 1, taxpr: 0 },
        { itemtype: "kpl", productType: "Vakuus", desc: "Vakuus", count: 1, taxpr: 0 },
        { itemtype: "hlö/kk", productType: "Vesi", desc: "Vesi", count: 1, taxpr: 0 },
        { itemtype: "kk", productType: "Vuokra", desc: "Vuokra", count: 1, taxpr: 0 },
      ];

      this.createProduct(products);
    },

    async changeAccountingIdToInvoices() {
      if (
        confirm(
          "Haluatko varmasti asettaa tiliöintikoodit kaikkiin luonnoslaskuihin ja aktiivisiin laskuihin?"
        )
      ) {
        try {
          let accountingIdFound = false;

          this.products.forEach((el) => {
            if (el.accountingId) {
              accountingIdFound = true;
            }
          });

          if (!accountingIdFound) {
            return this.showPopup("Liitä ensin tuotteisiin tiliöintikoodit", "error");
          }

          this.loadingAccountIds = true;
          this.showUpdateLoader = true;
          await this.changeProductAccountingIdToInvoices();
          this.loadingAccountIds = false;
          this.showUpdateLoader = false;
          this.showPopup("Laskut päivitetty", "success");
        } catch (err) {
          this.loadingAccountIds = false;
          this.showUpdateLoader = false;
          this.showPopup(err.message, "error");
        }
      }
    },

    deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa tuotteen: " + item.desc))
        this.inActivateProduct(item._id);
    },
  },
};
</script>

<style scoped></style>
